import axios from 'axios'
import _ from 'lodash'
import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'
import { Base64 } from 'js-base64'
import * as querystring from "querystring";

// 邮箱验证- 正则
export const emailReg = /^([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

export const getDeviceType = () => {
  const locationHref = window.location.href
  const result = locationHref.includes('iosAPP')
    ? 'iOS' : locationHref.includes('androidApk')
      ? 'android' : (/(iPhone|iPad|iPod|iOS|Mac)/i.test(navigator.userAgent))
        ? 'iOS' : 'android'
  return result
}

export const getLangCodeByLocaleName = (localeStr) => {
  return localeStr?.slice(-2).toLowerCase()
}

export const loadScript = (id, url) => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.id = id
  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
  return new Promise((resolve, reject) => {
    script.onload = function () {
      resolve()
    }
    script.onerror = function (err) {
      reject(err)
    }
  })
}

export const loadZendeskByLanguage = (language,callback) => {
  let key = '21e8f27e-9ee5-4df4-a53d-bd01295974a2';
  if (language !== 'zh-CN' && language !== 'zh-TW') {
    key = '91095ed8-3bbf-414b-941f-024bd8ff15ec';
  }
  const src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`
  loadScript('ze-snippet', src).then(() => {
    // console.log('机器人加载成功！')
    callback && callback();
  });
}

export const geetestCapcha = (localeStr, callback) => {
  let geetestLang = 'zho'
  switch (localeStr) {
    case 'zh-CN': {
      geetestLang = 'zho'
      break
    }
    case 'zh-TW': {
      geetestLang = 'zho-tw'
      break
    }
    case 'en': {
      geetestLang = 'eng'
      break
    }
    case 'ko': {
      geetestLang = 'kor'
      break
    }
    default: {
      geetestLang = 'zho'
      break
    }
  }

  const captchaConfig = {
    captchaId: process.env.CAPTCHA_ID, // 正式環境 captchaId由後台取得必须的配置参数
    product: 'bind',
    language: geetestLang
  }
  window.initGeetest4(captchaConfig, function (captchaObj) {
    captchaObj.onReady(function () {
      // 验证码ready之后才能调用showCaptcha方法显示验证码
      // console.log('captchaConfig', captchaConfig)
      captchaObj.showCaptcha() // 显示验证码
    }).onSuccess(function () {
      // 验证码正确的业务逻辑
      const geetestValid = JSON.parse(JSON.stringify(captchaObj.getValidate())) // deep copy
      Cookies.set('geetestValidTime', geetestValid.gen_time)
      delete geetestValid.captcha_id
      captchaObj.destroy()
      captchaObj = null
      callback()
    }).onFail(function (failObj) {
      // 验证码错误信息做一些统计
      setTimeout((_) => {
        captchaObj.destroy()
        captchaObj = null
      }, 2000)
    }).onError(function (err) {
      // your code
      console.error('err', err)
    })
  })
}

export const decryptLinks = (encryptedData) => {
  const _bytes = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Utf8.parse('MwxmPJJHmXasfr45'), {
    iv: CryptoJS.enc.Utf8.parse('yRKXEEPaEbHAbCPy')
  })
  const _links = _bytes.toString(CryptoJS.enc.Utf8).split(',')
  return _links
}

export const getData = async (url1, url2) => {
  let result = ''
  if (_.isNil(url1)) {
    return result
  }
  result = await axios.get(`${url1}?v=${new Date().getTime()}`)
    .then(res => res.data)
    .catch(err => console.log(err))
  if (_.isEmpty(result)) {
    result = await axios.get(`${url2}?v=${new Date().getTime()}`)
      .then(res => res.data)
      .catch(err => console.log(err))
  }
  return result
}

export const getAvailableSite = async (siteUrls, timeoutSec) => {
  const promises = []
  const sites = []
  await siteUrls.forEach(async (x) => {
    promises.push(
      await exists(x, timeoutSec).then((res) => {
        if (res) {
          sites.push(x)
        }
      })
    )
  })
  return Promise.all(promises).then(() => sites)
}

export const exists = async (url, timeoutSec) => {
  // 不設定即採用默認值 timeout 為 0
  const instance = axios.create()
  // 覆蓋原始 instance
  instance.defaults.timeout = _.isNil(timeoutSec) ? 2000 : timeoutSec * 1000
  const result = await instance.get(url)
    .then((res) => {
      // console.log(res)
      return res.status === 200
    })
    .catch((err) => {
      console.log(url, err)
      return false
    })
  return result
}

export const browserVersion = () => {
  var UA = navigator.userAgent;
  var tMatch;
  var M =
    UA.match(
      /(OPERA|CHROME|SAFARI|FIREFOX|MSIE|TRIDENT(?=\/))\/?\s*(\d+)/i
    ) || [];
  if (/TRIDENT/i.test(M[1])) {
    tMatch = /\brv[ :]+(\d+)/g.exec(UA) || [];
    return "IE " + (tMatch[1] || "");
  }
  if (M[1] === "Chrome") {
    tMatch = UA.match(/\b(OPR|Edg)\/(\d+)/);
    if (tMatch != null) {
      return tMatch
        .slice(1)
        .join(" ")
        .replace("OPR", "Opera")
        .replace("Edg", "Edge");
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tMatch = UA.match(/version\/(\d+)/i)) != null)
    M.splice(1, 1, tMatch[1]);
  return M.join(" ");
}

export const getBrowserType = () => {
  // 权重：系统 + 系统版本 > 平台 > 内核 + 载体 + 内核版本 + 载体版本 > 外壳 + 外壳版本
  const ua = navigator.userAgent.toLowerCase();
  const testUa = (regexp) => regexp.test(ua);
  const testVs = (regexp) =>
    ua
      .match(regexp)
      .toString()
      .replace(/[^0-9|_.]/g, "")
      .replace(/_/g, ".");
  //console.log("testUa:",testUa)
  // 系统
  let system = "unknow";
  if (testUa(/windows|win32|win64|wow32|wow64/g)) {
    system = "windows"; // windows系统
  } else if (testUa(/macintosh|macintel/g)) {
    system = "macos"; // macos系统
  } else if (testUa(/x11/g)) {
    system = "linux"; // linux系统
  } else if (testUa(/android|adr/g)) {
    system = "android"; // android系统
  } else if (testUa(/ios|iphone|ipad|ipod|iwatch/g)) {
    system = "ios"; // ios系统
  }
  // 系统版本
  let systemVs = "unknow";
  if (system === "windows") {
    if (testUa(/windows nt 5.0|windows 2000/g)) {
      systemVs = "2000";
    } else if (testUa(/windows nt 5.1|windows xp/g)) {
      systemVs = "xp";
    } else if (testUa(/windows nt 5.2|windows 2003/g)) {
      systemVs = "2003";
    } else if (testUa(/windows nt 6.0|windows vista/g)) {
      systemVs = "vista";
    } else if (testUa(/windows nt 6.1|windows 7/g)) {
      systemVs = "7";
    } else if (testUa(/windows nt 6.2|windows 8/g)) {
      systemVs = "8";
    } else if (testUa(/windows nt 6.3|windows 8.1/g)) {
      systemVs = "8.1";
    } else if (testUa(/windows nt 10.0|windows 10/g)) {
      systemVs = "10";
    }
  } else if (system === "macos") {
    systemVs = testVs(/os x [\d._]+/g);
  } else if (system === "android") {
    systemVs = testVs(/android [\d._]+/g);
  } else if (system === "ios") {
    systemVs = testVs(/os [\d._]+/g);
  }
  // 平台
  let platform = "unknow";
  if (system === "windows" || system === "macos" || system === "linux") {
    platform = "desktop"; // 桌面端
  } else if (
    system === "android" ||
    system === "ios" ||
    testUa(/mobile/g)
  ) {
    platform = "mobile"; // 移动端
  }
  // 内核和载体
  let engine = "unknow";
  let supporter = "unknow";
  if (testUa(/applewebkit/g)) {
    engine = "webkit"; // webkit内核
    if (testUa(/edge/g)) {
      supporter = "edge"; // edge浏览器
    } else if (testUa(/opr/g)) {
      supporter = "opera"; // opera浏览器
    } else if (testUa(/chrome/g) && testUa(/safari/g)) {
      supporter = "chrome"; // chrome浏览器
    } else if (testUa(/chrome/g)) {
      supporter = "chrome"; // chrome浏览器
    } else if (testUa(/safari/g)) {
      supporter = "safari"; // safari浏览器
    }
  } else if (testUa(/gecko/g) && testUa(/firefox/g)) {
    engine = "gecko"; // gecko内核
    supporter = "firefox"; // firefox浏览器
  } else if (testUa(/presto/g)) {
    engine = "presto"; // presto内核
    supporter = "opera"; // opera浏览器
  } else if (testUa(/trident|compatible|msie/g)) {
    engine = "trident"; // trident内核
    supporter = "iexplore"; // iexplore浏览器
  }
  // 内核版本
  let engineVs = "unknow";
  if (engine === "webkit") {
    engineVs = testVs(/applewebkit\/[\d._]+/g);
  } else if (engine === "gecko") {
    engineVs = testVs(/gecko\/[\d._]+/g);
  } else if (engine === "presto") {
    engineVs = testVs(/presto\/[\d._]+/g);
  } else if (engine === "trident") {
    engineVs = testVs(/trident\/[\d._]+/g);
  }
  // 载体版本
  /*let supporterVs = "unknow";
  if (supporter === "chrome") {
    supporterVs = testVs(/chrome\/[\d._]+/g);
  } else if (supporter === "safari") {
    supporterVs = testVs(/version\/[\d._]+/g);
  } else if (supporter === "firefox") {
    supporterVs = testVs(/firefox\/[\d._]+/g);
  } else if (supporter === "opera") {
    supporterVs = testVs(/opr\/[\d._]+/g);
  } else if (supporter === "iexplore") {
    supporterVs = testVs(/(msie [\d._]+)|(rv:[\d._]+)/g);
  } else if (supporter === "edge") {
    supporterVs = testVs(/edge\/[\d._]+/g);
  }*/
  // 外壳和外壳版本
  let shell = "none";
  let shellVs = "unknow";
  if (testUa(/micromessenger/g)) {
    shell = "wechat"; // 微信浏览器
    shellVs = testVs(/micromessenger\/[\d._]+/g);
  } else if (testUa(/qqbrowser/g)) {
    shell = "qq"; // QQ浏览器
    shellVs = testVs(/qqbrowser\/[\d._]+/g);
  } else if (testUa(/ucbrowser/g)) {
    shell = "uc"; // UC浏览器
    shellVs = testVs(/ucbrowser\/[\d._]+/g);
  } else if (testUa(/qihu 360se/g)) {
    shell = "360"; // 360浏览器(无版本)
  } else if (testUa(/2345explorer/g)) {
    shell = "2345"; // 2345浏览器
    shellVs = testVs(/2345explorer\/[\d._]+/g);
  } else if (testUa(/metasr/g)) {
    shell = "sougou"; // 搜狗浏览器(无版本)
  } else if (testUa(/lbbrowser/g)) {
    shell = "liebao"; // 猎豹浏览器(无版本)
  } else if (testUa(/maxthon/g)) {
    shell = "maxthon"; // 遨游浏览器
    shellVs = testVs(/maxthon\/[\d._]+/g);
  }
  return Object.assign(
    {
      engine, // webkit gecko presto trident
      engineVs,
      platform, // desktop mobile
      supporter, // chrome safari firefox opera iexplore edge
      // supporterVs,
      system, // windows macos linux android ios
      systemVs
    },
    shell === 'none'
      ? {}
      : {
        shell, // wechat qq uc 360 2345 sougou liebao maxthon
        shellVs
      }
  );
}

export const go2AppStore = () => {
  window.open('https://apps.apple.com/us/app/weex%E5%94%AF%E5%AE%A2-%E6%95%B0%E5%AD%97%E8%B4%A7%E5%B8%81%E5%90%88%E7%BA%A6%E4%BA%A4%E6%98%93%E5%B9%B3%E5%8F%B0-btc-eth/id1609350789');
}

export const go2GooglePlay = () => {
  window.open('https://play.google.com/store/apps/details?id=com.wake.weexprd');
}

/** 防攻击验证规则
第二位是5
4位是7
8位是8
15位是9
21位是7
30位是6 */
export const generateRandomString = (length) => {
  let randomString = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    randomString += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  // 将第二个字符替换为5
  randomString = replaceCharacter(randomString, 1, '5');
  // 将第四个字符替换为7
  randomString = replaceCharacter(randomString, 3, '7');
  // 将第八个字符替换为8
  randomString = replaceCharacter(randomString, 7, '8');
  // 将第十五个字符替换为9
  randomString = replaceCharacter(randomString, 14, '9');
  // 将第二十一个字符替换为7
  randomString = replaceCharacter(randomString, 20, '7');
  // 将第三十个字符替换为6
  randomString = replaceCharacter(randomString, 29, '6');
  return randomString;
}

function replaceCharacter (str, index, replacement) {
  return str.substr(0, index) + replacement + str.substr(index + 1);
}
// 监听窗口变化，合适时去web页面
export const monitorWindows = () => {
  setTimeout((_) => {
    getDeviceWidth()
    window.onresize = () => getDeviceWidth()
    window.onorientationchange = () => getDeviceWidth()
  })
}

export const getICPUrls = async (env) => {
  const isTest = env === 'test'
  const isStaging = env === 'staging'
  const isRc = env === 'rc'
  let icptxt = isStaging || isTest ? 'icp2.txt' : 'icp.txt'
  icptxt = isRc ? 'icp-rc.txt' : icptxt
  const siteFirst = `https://dswcnhr3lpzog.cloudfront.net/${icptxt}`
  const siteSecond = `https://weex-httpdns.oss-cn-shanghai.aliyuncs.com/${icptxt}`
  const ciphertext = await getData(siteFirst, siteSecond)
  return decryptLinks(ciphertext)
}

export const getOrientedUrls = async (env) => {
  const isTest = env === 'test'
  const isStaging = env === 'staging'
  const isRc = env === 'rc'
  let wwwtxt = isStaging || isTest ? 'www2.txt' : 'www.txt'
  wwwtxt = isRc ? 'www-rc.txt' : wwwtxt
  const siteFirst = `https://dswcnhr3lpzog.cloudfront.net/${wwwtxt}`
  const siteSecond = `https://weex-httpdns.oss-cn-shanghai.aliyuncs.com/${wwwtxt}`
  const ciphertext = await getData(siteFirst, siteSecond)
  return decryptLinks(ciphertext)
}

export const Languages = [
  'en',      // 0
  'zh-CN',   // 1
  undefined, // 2
  'ko',      // 3
  'vi',      // 4
  'zh-TW'    // 5
]

export const LanguageCodes = [
  'en_US',   // 0
  'zh_CN',   // 1
  undefined, // 2
  'ko_KR',   // 3
  'vi',      // 4
  'zh_TW'    // 5
]

export const NavigatorLanguages = [
  'en-US',   // 0
  'zh-CN',   // 1
  undefined, // 2
  'ko-KR',   // 3
  'vi',      // 4
  'zh-TW'    // 5
]

export const getLocaleByPath = (path, language) => {
  const languageType = getLanguageTypeByQuery(path) || language
  const localeFolder = path.split('/')[1]
  const locale = Languages.filter(value => value !== undefined).find(x => x.toLowerCase() === localeFolder.toLowerCase())
  return Languages[languageType] ??
          locale ??
          getLocaleByNavigator()
}

export const getLanguageTypeByQuery = (query) => {
  const search = '?' + query.split('?')[1]
  const urlParams = new URLSearchParams(search)
  const languageType = urlParams.get('languageType')
  return (languageType && Number(languageType)) ?? null
}

export const getLanguageCodeByPath = (pathname) => {
  const locale = getLocaleByPath(pathname)
  return getLanguageCodeByLocale(locale)
}

export const getLocaleByLanguageType = (languageType) => {
  return Languages[languageType] ?? Languages[0]
}

export const getLocaleByNavigator = () => {
  const defaultLocale = navigator.language
  console.log('defaultLocale------------->',defaultLocale)
  const index = NavigatorLanguages.indexOf(defaultLocale)
  return Languages[index] ?? Languages[0]
}

export const getLanguageTypeByLocale = (locale) => {
  return Languages.indexOf(locale) ?? 0
}

export const getLanguageCodeByLanguageType = (languageType) => {
  const index = languageType || 0
  return LanguageCodes[index] ?? LanguageCodes[0]
}

export const getLanguageCodeByLocale = (locale) => {
  const index = Languages.indexOf(locale) || 0
  return LanguageCodes[index] ?? LanguageCodes[0]
}

async function getDeviceWidth () {
  if (window.innerWidth >= 768) { // 大於768px切換至業務站
    const { href, hostname, search, pathname } = window.location
    console.log(search)
    const urlParams = new URLSearchParams(search)
    const isRegister = location.pathname.includes('register')
    const app = new EnvironmentCheck()
    app.checkEnvironment()
    const isTest = app.env === 'test'
    const isStaging = app.env === 'staging'
    const isRc = app.env === 'rc'
    const icpUrls = await getICPUrls(app.env)
    const isICP = icpUrls.includes(hostname)
    // console.log('app.env', app.env)Ï
    // console.log('icpUrls', icpUrls)
    // console.log('hostname', hostname)
    // console.log('isICP', isICP)
    if (isRegister) {
      const orientedUrls = await getOrientedUrls(app.env)
      let oriendtedUrl = orientedUrls[Math.floor(Math.random() * orientedUrls.length)] // 隨機取得一個導向站點

      // 针对RC环境中转站域名处理，RC环境中转站域名support.weex-rc.info | support.wtyku.cn
      if(isRc){
        oriendtedUrl = 'support.' + oriendtedUrl
      }

      const targetHost = isTest
        ? 'test-agency-oriented.weex.tech'
        : isStaging
          ? 'stg-agency-oriented.weex.tech'
          :isICP
            ? oriendtedUrl
            : isRc ? hostname.replace('support-', 'www-') : hostname.replace('support.', 'www.')

      // !urlParams.get('languageType') && urlParams.set('languageType', getLanguageTypeByQuery(href) ?? getLanguageTypeByLocale(getLocaleByPath(pathname)))
      // !urlParams.get('fromH5') && urlParams.set('fromSupport', Base64.encode(href))
      let targetPath =  '/register/';

      let languageType = Cookies.get("languageType"); // 获取本地缓存languageType
      const locale = getLocaleByPath(location.pathname, languageType); // 根据path和languageType获取locale
      languageType = getLanguageTypeByLocale(locale) // 根据locale获取languageType
      // 如果不是跳中转站则path拼上语言
      if(!isTest && !isStaging && !isICP){
        targetPath = '/' + locale + targetPath;
      }else {
        // 如果跳转中转站并且参数中没有languageType，则添加临时jumpLanguage参数，在中转站会在拿掉这个参数跳转官网
        if(!urlParams.has('languageType')){
          urlParams.set('jumpLanguage', languageType)
        }
      }
      const targetUrl = `https://${targetHost}${targetPath}?${urlParams.toString()}`
      // console.log('isTest', isTest)
      // console.log('isStaging', isStaging)
      // console.log('isICP', await isICP)
      // console.log('targetUrl', targetUrl)
      window.location.assign(targetUrl)
    }
  }
}

export class EnvironmentCheck {
  constructor () {
    this.env = 'unknown'
  }

  checkEnvironment () {
    const hostname = window.location.hostname

    if (['test-support.weex.tech'].includes(hostname)) {
      this.env = 'test'
    } else if (['localhost', 'stg-support.weex.tech', 'support.weikestg.info', 'support.mir992.com'].includes(hostname)) {
      this.env = 'staging'
    } else if (['support-rc.weex.tech', 'support-rc.weex-rc.info', 'support-rc.wtyku.cn'].includes(hostname)) {
      this.env = 'rc'
    } else {
      this.env = 'production'
    }
  }
}

/**
 * 有天的倒计时
 * @param {Number} times 时间戳
 * @param {Function} fn 倒计时中执行的方法
 * @param {Function} fnSun 倒计时完成后
 */
export const countDownForDay = (times, fn, fnSun) => {
  let timer = setInterval(function() {
    if (times < 1000) {
      fnSun();
      clearInterval(timer);
    }
    var days = parseInt(times / (24 * 1000 * 60 * 60));
    var hours = parseInt((times % (24 * 1000 * 60 * 60)) / (1000 * 60 * 60));
    var minutes = parseInt((times % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((times % (1000 * 60)) / 1000);
    if (days <= 9) days = '0' + days;
    if (hours <= 9) hours = '0' + hours;
    if (minutes <= 9) minutes = '0' + minutes;
    if (seconds <= 9) seconds = '0' + seconds;
    times = times - 1000;
    fn({ timer: timer, days, hours, minutes, seconds });
  }, 1000);
};
