<template>
  <section class="err-box" v-show="show">
    <img class="img" src="~assets/img/public/404.png" alt="404" />
    <h1 class="title">{{$t('common.error_404')}}</h1>
    <div class="home">
      <nuxt-link class="button" :to="$i18n.path('')">{{$t('common.go_home')}} ></nuxt-link>
    </div>
  </section>
</template>

<script>
export default {
  props: ["error"],
  created() {},
  data() {
    return {
      show: false
    };
  },
  mounted() {
    //关闭全局loading
    setTimeout(() => {
      hideGlobalLoading();
      this.show = true;
    }, 1000);
  }
};
</script>
<style lang="less" scoped>

.err-box {
  min-width: 910px;
  margin: 0 auto;
  margin-bottom: 40px;
  .img {
    display: block;
    width: 719px;
    margin-top: 30px;
    height: 273px;
    margin-bottom: 76px;
    margin-left: auto;
    margin-right: auto;
  }
  .title {
    font-size: 16px;
    color: @gray-P1;
    text-align: center;
  }
  .home {
    text-align: center;
  }
  .button {
    font-size: 14px;
    color: #5684f4;
    line-height: 14px;
  }
}
</style>
