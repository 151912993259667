
<template>
  <!-- 账户- 左侧菜单 -->
  <div class="main-weex">
    <!-- @note 彈窗提醒 -->
    <Popup v-if="popopupShow" :popupImg="popup_img" :popupLink="popup_link" :popupTitle="popup_title" :popupContent="popup_content"></Popup>
    <my-header />
    <div class="personal-main-container lay-box clearfix">
      <div class="personal-container">
        <div class="personal-left-box">
          <ul class="left-main-list">
            <li>
              <!-- 账户总览 -->
              <nuxt-link :to="$i18n.path('account')">
                <i class="iconfont icon-zonglan"></i>
                <span>{{ $t("user_aside.info") }}</span>
              </nuxt-link>
            </li>
            <li>
              <!-- 安全设置 -->
              <nuxt-link
                :to="$i18n.path('account/setting')"
                :class="{ active: settingActive }">
                <i class="iconfont icon-anquanshezhi1"></i>
                <span>{{ $t("user_aside.setting") }}</span>
              </nuxt-link>
            </li>
            <!-- 实名认证 -->
            <!-- <li>
              <nuxt-link
                :class="{ active: isClassActive('verified') }"
                :to="$i18n.path('account/verified')">
                <i class="iconfont icon-shiming1"></i>
                <span>{{ $t("user_aside.verified") }}</span>
              </nuxt-link>
            </li> -->
            <!-- api管理 -->
            <!-- <li>
              <nuxt-link :to="$i18n.path('account/newapi')">
                <i class="iconfont icon-APIguanli"></i>
                <span>{{ $t("user_aside.api") }}</span>
              </nuxt-link>
            </li> -->

            <!-- 福利中心 -->
<!--            <li v-if="getLang != 'zh-CN' && getLang != 'es' && getLang != 'en'">-->
<!--              <div class="menu" @click="welfareShow=!welfareShow">-->
<!--                <div class="menu-text">-->
<!--                  <i class="iconfont icon-fuli"></i>-->
<!--                  <span>{{$t('givingBft.giving_center')}}</span>-->
<!--                </div>-->
<!--                <i class="iconfont icon-up arrow" :class="{ show: welfareShow }"></i>-->
<!--              </div>-->
<!--              <transition name="el-zoom-in-top">-->
<!--                <div class="submenu" v-show="welfareShow">-->
<!--                  <ul>-->
<!--                    <li>-->
<!--                      <nuxt-link :to="$i18n.path('account/welfare/exclusive')">-->
<!--                        {{$t('user_setting.exclusive_welfare')}}-->
<!--                      </nuxt-link>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <nuxt-link :to="$i18n.path('account/welfare/mine')">-->
<!--                        {{$t('user_setting.my_welfare')}}-->
<!--                      </nuxt-link>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </transition>-->
<!--            </li>-->
            <li>
              <!-- 福利中心 -->
              <nuxt-link :to="$i18n.path('account/welfare')">
                <i class="iconfont icon-fuli"></i>
                <span>{{ $t("givingBft.giving_center") }}</span>
              </nuxt-link>
            </li>
            <li>
              <!-- 偏好设置 -->
              <nuxt-link :to="$i18n.path('account/preference')">
                <i class="iconfont icon-shezhi"></i>
                <span>{{ $t("user_aside.preference") }}</span>
              </nuxt-link>
            </li>
            <li>
              <!-- 幫助中心 -->
              <a  @click="gotoHelpCenter()">
                <i class="iconfont iconImg help"></i>
                <span>{{ $t("footer.help_center") }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="personal-main-box">
          <div class="personal-main-content">
            <nuxt />
          </div>
        </div>
      </div>
      <Telegram></Telegram>
    </div>
    <my-footer />
  </div>
</template>

<script>
import MyFooter from "~/web-base/components/Footer.vue";
import MyHeader from "~/web-base/components/Header.vue";
import Telegram from "~/web-base/components/Telegram.vue";
import Popup from "~/components/Popup.vue";
import {
  getPopUpData
} from "~/server/common/index.js";
import { mapGetters, mapState } from 'vuex'
export default {
  data() {
    return {
      settingActive: false,
      welfareShow: false,
      popopupShow: false, // 是否显示弹框 - 每天最多一次
      popup_img: [],
      popup_link:[],
      popup_title:[],
      popup_content:[]
    };
  },
  components: {
    Popup,
    MyFooter,
    MyHeader,
    Telegram
  },
  computed: {
    ...mapGetters(['getLang']),
    ...mapState(['loginHistoryArr']),
  },
  watch: {
    $route(to) {
      this.settingActive = this.isClassActive("setting");
    }
  },
  // beforeMount() {
  // },
  mounted() {
    this.settingActive = this.isClassActive("setting");
    window.isReadyPromise.then(() => {
      this.initGlobalPopup();
    });
  },
  methods: {
    //判断当前路由下 菜单是否active
    isClassActive(path) {
      if (this.$route.path.indexOf(path) > -1) {
        return true;
      }
      return false;
    },
    gotoHelpCenter(){
      let path = this.$t('newFooter.link.support_center')
      window.open(path);
      // window.location.href='https://weexsupport.zendesk.com/hc/zh-cn';
    },
    /**
     * 判断并开启弹框提醒 - 每天一次
     * date 代表日期
     * _1 代表登录不登录都弹的弹框已弹
     * _2 代表登录状态要弹的弹框已弹
     * _3 代表不登录状态要弹的弹框已弹
     */
    initGlobalPopup() {
      let date = new Date().toLocaleDateString();
      let localData = localStorage.getItem('global-popup-account');
      if (!(localData && localData.indexOf(date) > -1 && localData.indexOf('_1') > -1 && localData.indexOf('_2') > -1)) this.showPopUp(date, localData);
    },
    /**
     * 根据条件开启弹框提醒
     */
    showPopUp(date, localData) {
      getPopUpData().then(res => {
        if (res.code == '00000' && res.data.length > 0) {
          // 取出需要显示在个人中心的
          let list = res.data.filter(tmp => tmp.position == '2');
          // 取出非禁用状态的
          list = list.filter(tmp => tmp.status == 'ENABLE');
          // 排除非登录弹框
          list = list.filter(tmp => tmp.loginStatus != '3');
          // 登录非登录都弹的弹框 如果已经弹过，则排除
          if (!localData) localData = date;
          else if (localData.indexOf(date) < 0) localData = date;
          if (localData.indexOf('_1') > -1) list = list.filter(tmp => tmp.loginStatus != '1'); else localData += '_1';
          // 登录状态要弹的弹框 如果已经弹过，则排除
          if (localData.indexOf('_2') > -1) list = list.filter(tmp => tmp.loginStatus != '2'); else localData += '_2';
          // 过滤语系
          if (this.getLang == 'zh-CN') {
            list = list.filter(tmp => tmp.languageType == '1');
          } else if (this.getLang == 'zh-TW') {
            list = list.filter(tmp => tmp.languageType == '2');
          } else if (this.getLang == 'en') {
            list = list.filter(tmp => tmp.languageType == '3');
          } else if (this.getLang == 'ko') {
            list = list.filter(tmp => tmp.languageType == '4');
          } else if (this.getLang == 'ja') {
            list = list.filter(tmp => tmp.languageType == '5');
          }
          // 整理弹框数据
          if (list && list.length > 0) {
            list.forEach((item, index) => {
              this.popup_img[index] = item.picUrl; //图片
              this.popup_link[index] = item.forward; //跳转URL
              this.popup_title[index] = item.tipType == '2' ? item.title : ''; //标题
              this.popup_content[index] = item.tipType == '2' ? item.content : ''; //富文本内容
            });
            // 显示弹框
            this.popopupShow = true;
            // 设置今天已经弹过
            localStorage.setItem('global-popup-account', localData);
          }
        } else if (res.code != '00000') {
            BT.util.info(res.msg || 'error...');
            console.log("getPopUpData error")
          }
      })
    },
  }
};
</script>

<style lang="less">
.hover_a() {
  display: flex;
  align-items: center;
  padding-left: 32px;
  padding-right: 24px;
  font-size: 14px;
  color: @text-black;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  &:hover,
  &.active,
  &.nuxt-link-exact-active {
    background: @bg-color-tab-active;
    color: @yellow-P3;
    border-color: @yellow-P3;
    i {
      color: @yellow-P3;
    }
    .help {
      width: 16px;
      height: 16px;
      background: url("~assets/img/public/helpCenter2.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  i {
    font-size: 16px;
    margin-right: 10px;
    color: @text-minor-color;
  }
}

.personal-main-container {
  .personal-container {
    display: flex;
    justify-content: space-between;
  }
  .personal-left-box {
    min-width: 240px;
    max-width: 264px;
    width: 15%;
    box-sizing: border-box;
    padding-top: 40px;
    border-right: 1px solid @table-header-color-border;

    .left-main-list {
      > li {
        height: auto;
        min-height: 48px;
        line-height: 1.2;
        > a {
          .hover_a();
          display: flex;
          align-items: center;
          line-height: 1.2;
          >span{
            position: relative;
            display: inline-block;
            width: 100%;
            font-size: 14px;
            line-height: 1.2;
            white-space: unset;

            .icon-newhot{
              position: absolute;
              right: -10px;
            }
          }
        }

        //有下级菜单的
        .menu {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 32px;
          padding-right: 24px;
          box-sizing: border-box;
          cursor: pointer;
          width: 100%;
          .arrow {
            font-size: 12px;
            color: @text-normal-color;
            transform: scale(0.6) rotate(180deg);
            transition: all 0.2s;
            &.show {
              transform: scale(0.6) rotate(0deg);
              transition: all 0.2s;
            }
          }
          .menu-text {
            display: flex;
            align-items: center;
            line-height: 1;
            i {
              font-size: 16px;
              margin-right: 10px;
              color: @text-minor-color;
            }
          }
        }
        .submenu {
          ul {
            > li {
              height: auto;
              min-height: 48px;
              line-height: 1.2;
              > a {
                .hover_a();
                line-height: 1.2;
                padding-left: 58px;
                color: @text-normal-color;
              }
            }
          }
        }
        .iconImg {
          width: 16px;
          height: 16px;
          background-size: contain;
          background-repeat: no-repeat;
        }
        .help{
          background-image: url("~assets/img/public/helpCenter.png");
        }
      }
    }
  }
  //右侧区域
  .personal-main-box {
    flex: 1;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 48px;
    display: flex;
    justify-content: center;
    .personal-main-content {
      flex: 1;
      min-width: 960px;
      max-width: 1560px;
      min-height: 660px;
      padding-bottom: 40px;
    }
  }
}
</style>
