<template>
  <div class="main-weex">
    <my-header :needBorderBottom="Boolean(0)"/>
    <div class="main-box clearfix lay-box">
      <nuxt/>
          <Telegram></Telegram>
    </div>
    <my-footer v-if="!isShowFooter"/>
  </div>
</template>

<script>
  import MyFooter from "~/web-base/components/Footer.vue";
  import MyHeader from "~/web-base/components/Header.vue";
  import Telegram from "~/web-base/components/Telegram.vue";

  export default {
    components: {
      MyFooter,
      MyHeader,
      Telegram
    },
    data() {
      return {
        isShowFooter: this.$route.fullPath.indexOf('/promotion') > -1,
        __nuxt: null,
        __nuxtClass: ""
      }
    },
    mounted() {
      this.__nuxt = document.getElementById("__nuxt");
      this.__nuxtClass = this.__nuxt.className;
      this.__nuxt.className = this.__nuxtClass + " no-footer";
    },
    beforeDestroy() {
      this.$nextTick(() => {
        this.__nuxt.className = this.__nuxtClass;
      });
    }
  };
</script>
