<template>
  <client-only>
    <div class="main-weex">
      <my-header />
      <!-- 海外交易大赛 -->
      <div class="challenge-box">
        <!-- banner -->
        <div class="banner-box">
          <h1 class="challenge-h1" v-html="$t('activity_challenge.h1')"></h1>
          <h2 class="challenge-h2">
            <span>
              <img src="~/assets/img/challenge/h2-bg.png" alt="">
              {{$t('activity_challenge.h2', [numFormat(totalAmount)])}}
              <img src="~/assets/img/challenge/h2-bg.png" alt="" class="reverse">
            </span>
          </h2>
          <div class="challenge-countdown-text">
            <template v-if="activiticyStatus === -1">
              본 이벤트는 아직 시작되지 않았습니다.
            </template>
            <template v-if="activiticyStatus === 0">
              {{$t('activity_challenge.countdown_text_1')}}
            </template>
            <template v-if="activiticyStatus === 1">
              {{$t('activity_challenge.countdown_text_2')}}
            </template>
            <template v-if="activiticyStatus === 2">
              {{$t('activity_challenge.countdown_text_3')}}
            </template>
            <template v-if="activiticyStatus === 3">
              {{$t('activity_challenge.countdown_text_4')}}
            </template>
             <template v-if="activiticyStatus === 4">
              {{$t('activity_challenge.act_over')}}
            </template>
          </div>

          <!-- 倒计时 -->
          <div class="challenge-countdown-wrap">
            <div class="challenge-countdown-box">
              <span>{{countDownObj.D[0] || '--'}}</span>
              <span>{{countDownObj.D[1] || '--'}}</span>
              <i>{{$t('activity_challenge.d')}}</i>
              <span>{{countDownObj.H[0] || '--'}}</span>
              <span>{{countDownObj.H[1] || '--'}}</span>
              <i>{{$t('activity_challenge.h')}}</i>
              <span>{{countDownObj.M[0] || '--'}}</span>
              <span>{{countDownObj.M[1] || '--'}}</span>
              <i>{{$t('activity_challenge.m')}}</i>
            </div>
          </div>
        </div>

        <!-- 3个时段切换 -->
        <div class="schedule-box clearfix" id="elMember" >
          <div class="schedule-item first" :class="{active: activiticyStatus == 0}">
            <div class="schedule-title">{{$t('activity_challenge.stage_1')}}</div>
            <div>{{dateFormat(actTimes.captainBegin, 'MM월dd일')}} - {{dateFormat(actTimes.captainEnd, 'MM월dd일')}}</div>
          </div>
          <div class="schedule-item second" :class="{active: activiticyStatus == 1}">
            <div class="schedule-title">{{$t('activity_challenge.stage_2')}}</div>
            <div>{{dateFormat(actTimes.teamBegin, 'MM월dd일')}} - {{dateFormat(actTimes.teamEnd, 'MM월dd일')}}</div>
          </div>
          <div class="schedule-item last" :class="{active: activiticyStatus == 2}">
            <div class="schedule-title">{{$t('activity_challenge.stage_3')}}</div>
            <div>{{dateFormat(actTimes.formalBegin, 'MM월dd일')}} - {{dateFormat(actTimes.formalEnd, 'MM월dd일')}}</div>
          </div>
        </div>

        <!-- 战队详情 -->
        <template v-if="currPath.indexOf('/member') > -1">
          <nuxt/>
        </template>

        <!-- 非战队详情 -->
        <template v-else>
          <!-- 团队赛和个人赛的切换 -->
          <div class="challenge-type-box clearfix" v-if="activiticyStatus>=2">
            <div class="challenge-type-item" :class="{active: typeIndex==1}">
              <span @click="setTypeIndex(1)">{{$t('activity_challenge.team_rank_title')}}</span>
            </div>
            <div class="challenge-type-item" :class="{active: typeIndex==2}">
              <span @click="setTypeIndex(2)">{{$t('activity_challenge.personal_rank_title')}}</span>
            </div>
            <div class="challenge-type-tip">
              <span>{{$t('activity_challenge.had_team_count', [teamTotalNum])}}</span>
              <span>{{$t('activity_challenge.had_personal_count', [personalTotalNum])}}</span>
              <span>{{$t('activity_challenge.refresh_data', [refreshRhythm, dateFormat(refreshTime, 'HH:mm')])}}</span>
            </div>
          </div>

          <!-- 队长申请期 -->
          <template v-if="activiticyStatus==0">
            <!-- 队长申请状态 -->
            <div class="captain-status-box">
              <!-- 1: 未申请队长 -->
              <template v-if="captainStatus==1">
                <div class="captain-form-box">
                  <div class="captain-form">
                    <div class="form-wrap clearfix">
                      <span class="captain-form-label"><i>{{$t('activity_challenge.captain_name')}}</i></span>
                      <span class="captain-form-input"><input type="text" maxlength="30" v-model="iptCaptainName" @blur="captainNameBlur"></span>
                    </div>
                    <div class="captain-form-error">
                      <v-errtip class="form-error-text" :error="captainNameErr"></v-errtip>
                    </div>
                  </div>
                  <div class="captain-form">
                    <div class="form-wrap clearfix">
                      <span class="captain-form-label">
                        <i>{{$t('activity_challenge.contact_type')}}</i>
                        <label>
                          <div class="label-border" @click="contactType=1" :class="{active: contactType == 1}">
                            <span>email</span>
                          </div>
                          <div class="label-border" @click="contactType=2" :class="{active: contactType == 2}">
                            <span>Telegram</span>
                          </div>
                        </label>
                      </span>
                      <span class="captain-form-input"><input maxlength="80" type="text" v-model="iptCaptainTel" @blur="captainTelBlur"></span>
                    </div>
                    <div class="captain-form-error">
                      <v-errtip class="form-error-text" :error="captainTelErr"></v-errtip>
                    </div>
                  </div>
                </div>
                <div class="captain-status-btn apply">
                  <challenge-btn :big="Boolean(1)" :disabled="applyCaptainDisabled" :text="$t('activity_challenge.apply_captain_btn')" @click="applyCaptain"></challenge-btn>
                </div>
              </template>

              <!-- 2：已申请队长，审核中 -->
              <template v-if="captainStatus==2">
                <img class="captain-status-img" src="~assets/img/challenge/captain-success.png" alt="">
                <div class="captain-status-title">{{$t('activity_challenge.captain_status_1')}}</div>
                <div class="captain-status-subtitle">{{$t('activity_challenge.captain_status_1_1')}}</div>
              </template>

              <!-- 3：已申请队长，审核通过 -->
              <template v-if="captainStatus==3">
                <img class="captain-status-img" src="~assets/img/challenge/captain-applyed.png" alt="">
                <div class="captain-status-title">{{$t('activity_challenge.captain_status_2')}}</div>
                <div class="captain-status-subtitle">{{$t('activity_challenge.captain_status_2_1', [teamTimeLimit])}}</div>
              </template>

              <!-- 4：已申请队长，审核未通过 -->
              <template v-if="captainStatus==4">
                <img class="captain-status-img" src="~assets/img/challenge/captain-fail.png" alt="">
                <div class="captain-status-title">{{$t('activity_challenge.captain_status_3')}}</div>
                <div class="captain-status-subtitle">{{$t('activity_challenge.captain_status_3_1')}} {{captainFailReason}}</div>
                <div class="captain-status-btn">
                  <challenge-btn :disabled="Boolean(0)" :text="$t('activity_challenge.btn_confirm')" @click="setCaptainStatus(1)"></challenge-btn>
                </div>
              </template>

              <!-- 5：申请已过期 -->
              <template v-if="captainStatus==5">
                <img class="captain-status-img" src="~assets/img/challenge/captain-over.png" alt="">
                <div class="captain-status-title">{{$t('activity_challenge.captain_status_4')}}</div>
              </template>
            </div>
          </template>

          <!-- 创建战队和正式比赛 -->
          <template v-if="activiticyStatus > 0">
            <!-- 创建战队，只有队长角色能 -->
            <template v-if="activiticyStatus==1 && captainStatus==3">
              <!-- 没有战队时 -->
              <div class="captain-status-box" v-if="!myTeam.name">
                <img class="captain-status-img" src="~assets/img/challenge/create-team.png" alt="">
                <div class="captain-status-btn">
                  <challenge-btn :big="Boolean(1)" :disabled="createTeamDisabled" :text="$t('activity_challenge.create_team_btn')" @click="createTeam"></challenge-btn>
                </div>
              </div>
            </template>

            <template v-if="typeIndex==1">
              <!-- 有自己的战队时 -->
              <div class="captain-status-box is-captain" :class="{low: activiticyStatus==2}" v-if="myTeam.name">
                <div class="my-team-info clearfix">
                  <div class="avatar-box">
                    <img :src="myTeam.logo" alt="">
                  </div>
                  <div class="team-base">
                    <span class="team-info-type">{{myTeam.name || '- -'}}<i v-if="myTeam.type == 1 && isCaptain" class="team-invite-code">{{$t('activity_challenge.invite_code')}}: {{myTeam.inviteCode}}<img class="icon-copy" @click="copyKey(myTeam.inviteCode)" src="~assets/img/challenge/copy.svg" alt=""></i></span>
                    <span>{{myTeam.declaration || '- -'}}</span>
                  </div>
                  <div class="team-info-btn">
                    <challenge-btn :text="$t('activity_challenge.see_team_info')" @click="showTeamInfoFn(myTeam.id)"></challenge-btn>
                  </div>
                </div>
                <div class="my-team-total clearfix">
                  <div>
                    <span>{{myTeam.rank}}</span>
                    <span>{{$t('activity_challenge.my_team_rank')}}</span>
                  </div>
                  <div>
                    <span>{{myTeam.total}}</span>
                    <span>{{$t('activity_challenge.my_team_total')}}</span>
                  </div>
                  <div>
                    <span>{{myTeam.selfRank || '- -'}}</span>
                    <span>{{$t('activity_challenge.my_in_team_rank')}}</span>
                  </div>
                  <div>
                    <span>{{myTeam.selfTotal}}</span>
                    <span>{{$t('activity_challenge.my_rank')}}</span>
                  </div>
                </div>
              </div>

              <!-- 战队成员列表 -->
              <team-list :can="isCanJoin && activiticyStatus < 3" @joinSuccess="joinSuccessFn"></team-list>
            </template>

            <!-- 个人排行榜 -->
            <template v-if="typeIndex==2">
              <personal-list></personal-list>
            </template>
          </template>

        </template>

        <!-- 规则 -->
        <rule-box :reward-time="dateFormat(actTimes.overBegin, 'yyyy-MM-dd') + ' ~ ' + dateFormat(actTimes.overEnd, 'yyyy-MM-dd')" :begin="actTimes.formalBegin" :end="actTimes.formalEnd" :list="actTimes"></rule-box>

        <!-- 创建战队弹窗 -->
        <create-team :show.sync="createTeamDialog" @success="createSuccessFn"></create-team>

        <!-- 先去登录 -->
        <go-login :show.sync="loginDialogShow"></go-login>

        <!-- 再去实名 -->
        <go-real-vali :is-join="isJoin" :show.sync="realValiDialogShow"></go-real-vali>

      </div>

      <Telegram></Telegram>
      <my-footer />
    </div>
  </client-only>
</template>

<script>
import MyFooter from "~/web-base/components/Footer.vue";
import MyHeader from "~/web-base/components/Header.vue";
import Telegram from "~/web-base/components/Telegram.vue";
import common from '~/common/common.js';
import { countDownForDay, getLens, formatDate, toThousands } from "~/utils/utils.js";
import rule from '@/components/challenge/rule'
import challengeBtn from '@/components/challenge/challengeBtn'
import teamList from '@/components/challenge/teamList'
import personalList from '@/components/challenge/personalList'
import goLogin from '@/components/challenge/goLogin'
import goRealVali from '@/components/challenge/goRealVali'
import { actInfo, personalSignUp, teamSignUp, teamJoin, teamMembers, teamInfo, personalRank, personalInfo, logoList } from '~/server/activity/challenge.js'
import { getCurrentTime } from '~/server/common/index'
import { mapGetters, mapState } from 'vuex'
export default {
  components: {
    MyFooter,
    MyHeader,
    Telegram,
    'rule-box': rule,
    challengeBtn,
    teamList,
    personalList,
    goRealVali,
    goLogin,
    'create-team': () => import('~/components/challenge/createTeam'),
    'join-team': () => import('~/components/challenge/joinTeam'),
  },
  data() {
    return {
      actTimes: {
        captainBegin: 0,
        captainEnd: 0,
        teamBegin: 0,
        teamEnd: 0,
        formalBegin: 0,
        formalEnd: 0,
        overBegin: 0,
        overEnd: 0,
      },
      timer: null, // banner 倒计时
      countDownObj: {
        D: '--',
        H: '--',
        M: '--',
        S: '--'
      },
      activiticyStatus: -1, // -1: 未开始，0：队长申请期，1：创建战队期，2：正式比赛期，3：颁奖期 4 完成 数据不再展示
      isCaptain: false, // 是否队长
      captainStatus: 1, // 1: 未申请队长，2：已申请队长，审核中，3：已申请队长，审核通过，4：已申请队长，审核未通过，5：申请已过期

      // 申请队长相关
      iptCaptainName: '',
      iptCaptainTel: '',
      captainNameErr: '',
      captainTelErr: '',
      contactType: 1, // 1: email； 2：telegram
      captainFailReason: '',
      applyCaptainDisabled: false,
      createTeamDisabled: false,
      createTeamDialog: false, // 创建战队弹窗
      loginDialogShow: false, // 要去登录弹窗
      realValiDialogShow: false, // 实名弹窗
      showTeamInfoDisabled: false,
      typeIndex: 1,
      myTeam: {
        name: '',
        declaration: '',
        type: 0,
        logo: '',
        total: 0,
        selfRank: '- -',
        selfTotal: 0,
        inviteCode: ''
      },
      refreshRhythm: 10, // 每x分钟刷新
      refreshTime: '00:00',
      teamTotalNum: 0,
      personalTotalNum: 0,
      totalAmount: '', // banner 的奖励金额
      isCanJoin: true,
      teamId: '',
      isJoin: false,
      refreshTimer: null, // 10分钟刷新的计时器
    }
  },
  computed: {
    ...mapGetters(['isLogin']),
    ...mapState(['user']),
    // 队长申请时间段
    teamTimeLimit() {
      return formatDate(this.actTimes.teamBegin, 'yyyy-MM-dd') + ' ~ ' + formatDate(this.actTimes.teamEnd, 'yyyy-MM-dd');
    },
    currPath() {
      return this.$route.path
    }
  },
  mounted() {
    this.getActInfo();
  },
  watch: {
    // 切换联系方式
    contactType(val) {
      this.iptCaptainTel = '';
      this.captainTelErr = '';
    },
    iptCaptainName(val) {
      if(val) {
        if(!this.valiName(val)) {
          this.captainNameErr = this.$t('activity_challenge.captain_name_err');
          return;
        }
      } else {
        this.captainNameErr = '';
        return;
      }
    },
    iptCaptainTel(val) {
      if(!val) {
        this.captainTelErr = '';
        return;
      } else {
        if(this.contactType == 1 && !common.emailReg.test(val)) {
          this.captainTelErr = this.$t('activity_challenge.captain_email_err');
          return;
        }
        if(this.contactType == 2) {
          if(!common.telegramReg.test(val)) {
            this.captainTelErr = this.$t('activity_challenge.captain_tel_err');
            return;
          }
        }
      }
      this.captainTelErr = '';
    },
  },
  methods: {
    getActInfo(loop = false) {

      //如果有倒计时 清除倒计时
      clearInterval(this.refreshTimer);
      actInfo({activityId: 1}).then(res => {
        hideGlobalLoading();
        if(res.code === '00000') {
          let at = res.data.activityTimes || [];
          let currTime = res.requestTime;
          at.forEach((item) => {
            // 队长报名
            if(item.status == 10) {
              this.actTimes.captainBegin = item.beginTime;
              this.actTimes.captainEnd = item.endTime;
            }

            // 战队报名
            if(item.status == 11) {
              this.actTimes.teamBegin = item.beginTime;
              this.actTimes.teamEnd = item.endTime;
            }

            // 正式比赛
            if(item.status == 20) {
              this.actTimes.formalBegin = item.beginTime;
              this.actTimes.formalEnd = item.endTime;
            }

            // 颁奖中
            if(item.status == 30) {
              this.actTimes.overBegin = item.beginTime;
              this.actTimes.overEnd = item.endTime;
            }
          });

          this.totalAmount = res.data.amount || 0;
          this.refreshRhythm = res.data.dateRefresh || 10;
          this.refreshTime = res.data.lastRefresh || '- -';
          this.teamTotalNum = res.data.teamSize || 0;
          this.personalTotalNum = res.data.personalSize || 0;

          if(res.data.actStatus == 0 || res.data.actStatus == null) {
            this.activiticyStatus = -1;
          } else if(res.data.actStatus == 10) {
            this.activiticyStatus = 0;
          } else if(res.data.actStatus == 11) {
            this.activiticyStatus = 1;
          } else if(res.data.actStatus == 20) {
            this.activiticyStatus = 2;

            //比赛进行中  数据轮询获取 refreshRhythm 分钟轮询
            // 获取参赛的人数  及 参赛的队伍
            this.refreshTimer = setInterval(() => {
              clearInterval(this.timer);
              this.getActInfo(true);
            }, 1000 * 60 * this.refreshRhythm);
          } else if(res.data.actStatus == 30) {
            this.activiticyStatus = 3;
          } else {
            this.activiticyStatus = 4;
          }

          let diff = 0;
          if(this.activiticyStatus == 0) {
            diff = this.actTimes.captainEnd - currTime;
          }
          if(this.activiticyStatus == 1) {
            diff = this.actTimes.teamEnd - currTime;
          }
          if(this.activiticyStatus == 2) {
            diff = this.actTimes.formalEnd - currTime;
          }

          if(diff > 0) {
            countDownForDay(
              diff,
              this.countdownFn(this.countDownObj),
              this.countdownSucc
            );
          }

          window.isReadyPromise.then(()=>{
            if(this.isLogin && !loop) {
              this.getMyInfo();
            }
          });
        }
      });
    },
    getMyInfo() {
      this.applyCaptainDisabled = false;
      personalInfo({activityId: 1}).then(res => {
        if(res.code === '00000') {
          // res.data.status: -1没申请过 0待审核 1审核通过 2审核失败
          if(res.data.status == -1) {
            this.captainStatus = 1;
          }
          if(res.data.status == 0) {
            this.captainStatus = 2;
          }
          if(res.data.status == 1) {
            this.captainStatus = 3;
          }
          if(res.data.status == 2) {
            this.captainStatus = 4;
            this.captainFailReason = res.data.reason;
          }

          // 如果是队长
          if(res.data.captain) {
            this.isCanJoin = false;
            this.isCaptain = true;
          } else {
            // 是队员
            if(res.data.teamId) {
              this.isCanJoin = false;
            }
            this.isCaptain = false;
          }

          this.myTeam = {
            id: res.data.teamId,
            name: res.data.teamName,
            rank: res.data.teamRank || '- -',
            declaration: res.data.declaration,
            type: res.data.type,
            logo: res.data.teamLogo,
            total: res.data.teamTotal || 0,
            selfRank: res.data.personalRankByTeam || '- -',
            selfTotal: res.data.personalTotal || 0,
            inviteCode: res.data.inviteCode,
          };

        }
      });
    },

    // 创建战队成功，更新状态
    createSuccessFn() {
      this.getMyInfo();
    },

    // 加入战队成功，更新状态
    joinSuccessFn() {
      this.getMyInfo();
    },

    // 战队或个人排行榜
    setTypeIndex(index) {
      if(this.typeIndex == index) {
        return;
      }
      this.typeIndex = index;
    },

    setCaptainStatus(val) {
      this.captainStatus = val;
    },

    showTeamInfoFn(id) {
      this.teamId = id;
      this.$router.push(
        this.$i18n.path(`challenging/member?id=${id}#elMember`)
      );
    },

    // banner 倒计时相关
    countdownSucc() {
      this.countDownObj.D = '00';
      this.countDownObj.H = '00';
      this.countDownObj.M = '00';
      this.countDownObj.S = '00';
      clearInterval(this.timer);
      this.getActInfo();
    },
    countdownFn(obj) {
      return timeObj => {
        obj.D = timeObj.days.toString();
        obj.H = timeObj.hours.toString();
        obj.M = timeObj.minutes.toString();
        obj.S = timeObj.seconds.toString();
        this.timer = timeObj.timer;
      };
    },

    dateFormat(str, format) {
      return formatDate(str, format);
    },

    numFormat(num) {
      return toThousands(num);
    },

    captainNameBlur() {
      if(this.iptCaptainName) {
        if(!this.valiName(this.iptCaptainName)) {
          this.captainNameErr = this.$t('activity_challenge.captain_name_err');
          return false;
        }
      } else {
        this.captainNameErr = this.$t('activity_challenge.captain_name_required');
        return false;
      }
      this.captainNameErr = '';
      return true;
    },

    captainTelBlur() {
      if(!this.iptCaptainTel) {
        if(this.contactType == 1) {
          this.captainTelErr = this.$t('activity_challenge.captain_email_required');
        } else {
          this.captainTelErr = this.$t('activity_challenge.captain_tel_required');
        }
        return false;
      } else {
        if(this.contactType == 1 && !common.emailReg.test(this.iptCaptainTel)) {
          this.captainTelErr = this.$t('activity_challenge.captain_email_err');
          return false;
        }
        if(this.contactType == 2) {
          if(!common.telegramReg.test(this.iptCaptainTel)) {
            this.captainTelErr = this.$t('activity_challenge.captain_tel_err');
            return false;
          }
        }
      }
      this.captainTelErr = '';
      return true;
    },

    // 申请队长
    applyCaptain() {
      if(this.isLogin) {
        if(!this.user.hasRealValidate) {
          this.isJoin = false;
          this.realValiDialogShow = true;
          return;
        }

        let flag = true;
        flag = this.captainNameBlur();
        flag = this.captainTelBlur();
        if(!flag) {
          return;
        }

        this.applyCaptainDisabled = true;
        let params = {
          activityId: 1,
          name: this.iptCaptainName
        }
        if(this.contactType == 1) {
          params.email = this.iptCaptainTel;
        } else {
          params.telegram = this.iptCaptainTel;
        }
        personalSignUp(params).then(res => {
          if(res.code === '00000') {
            this.captainStatus = 2;
          } else if(res.code === '70015') {
            this.captainNameErr = res.msg;
          } else {
            this.applyCaptainDisabled = false;
            if(res.msg) {
              this.captainTelErr = res.msg;
            }
          }
        });
      } else {
        this.loginDialogShow = true;
        return;
      }
    },

    // 创建战队
    createTeam() {
      if(this.isLogin) {
        if(!this.user.hasRealValidate) {
          this.isJoin = true;
          this.realValiDialogShow = true;
          return;
        }
        this.createTeamDialog = true;
      } else {
        this.loginDialogShow = true;
        return;
      }
    },

    valiName(str) {
      if(str) {
        if(getLens(str) < 2 || getLens(str) > 20) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },

    getLastNum(num, index) {
      let s = num.toString();
      return s[index] || '-';
    },

    copyKey(str) {
      let _this = this;
      this.$copyText(str).then(
        function(e) {
          BT.util.success(
            _this.$i18n.t("common.copy_success"),
            _this.$t("common.tips")
          );
        },
        function(e) {}
      );
    },
  },
  beforeDestroy() {
    if(this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    if(this.refreshTimer) {
      clearInterval(this.refreshTimer);
      this.refreshTimer = null;
    }
  }
}
</script>

<style lang="less">
.challenge-box{
  margin-top: -104px;
  background-color: #1A1A1E;
  // banner
  .banner-box{
    max-width: 1920px;
    height: 742px;
    margin: 0 auto;
    background: url("~assets/img/challenge/banner.png") top center no-repeat;
    background-size: auto 100%;

    .challenge-h1{
      display: block;
      padding-top: 148px;
      font-size: 54px;
      color: #FFF;
      text-align: center;
    }
    .challenge-h2{
      display: block;
      margin-top: 11px;
      text-align: center;
      span{
        position: relative;
        display: inline-block;
        height: 74px;
        line-height: 74px;
        font-size: 52px;
        padding: 0 42px;
        color: #FFf;
        text-align: center;
        background-image: linear-gradient(227deg, #FF6F3E 0%, #F90E0E 100%);
        border-radius: 4px;
      }
      img{
        position: absolute;
        top: 3px;
        left: 5px;
        display: block;
        width: 30px;
        height: 20px;

        &.reverse{
          top: auto;
          left: auto;
          bottom: 3px;
          right: 5px;
          transform: rotateX(180deg) rotateY(180deg);
        }
      }
    }
    .challenge-countdown-text{
      margin-top: 24px;
      text-align: center;
      font-size: 24px;
      color: #FFF;
    }

    .challenge-countdown-wrap{
      display: flex;
      justify-content: center;
      margin-top: 16px;

      .challenge-countdown-box{
        display: flex;
        align-items: center;
        justify-self: center;
        text-align: center;
        span{
          display: inline-block;
          width: 42px;
          height: 56px;
          line-height: 56px;
          margin-right: 7px;
          text-align: center;
          font-size: 30px;
          color: #181D2C;
          background: #fff;
          border-radius: 8px;
        }
        i{
          display: inline-block;
          padding: 0 9px 0 7px;
          font-style: normal;
          font-size: 26px;
          color: #FFF;
        }
      }
    }
  }

  // 切换
  .schedule-box{
    width: 1020px;
    margin: 12px auto 0;

    .schedule-item{
      position: relative;
      float: left;
      width: 300px;
      height: 130px;
      color: #fff;
      font-size: 16px;

      background: url("~assets/img/challenge/tab-bg.png") top center no-repeat;
      background-size: auto 100%;

      div{
        margin-left: 25px;
      }
      .schedule-title{
        margin-top: 35px;
        margin-bottom: 5px;
        font-size: 22px;
      }

      .forTriangle() {
        content: "";
        position: absolute;
        display: block;
        top: 44%;
        width: 0;
        height: 0;
        border-style: solid;
        z-index: 2;
      }
      .leftTriangle(@c: #636372){
        .forTriangle();
        left: -12px;
        border-width: 12px;
        border-color: @c;
        border-left-width: 0;
        border-style: dashed;
        border-right-style: solid;
        border-top-color: transparent;
        border-bottom-color: transparent;
      }
      .rightTriangle(@c: #636372) {
        .forTriangle();
        right: -12px;
        border-width: 12px;
        border-color: @c;
        border-right-width: 0;
        border-style: dashed;
        border-left-style: solid;
        border-top-color: transparent;
        border-bottom-color: transparent;
      }

      &.first{
        &::after{
          .rightTriangle();
        }
      }
      &.second{
        margin: 0 42px;
        &::before{
          .leftTriangle();
        }
        &::after{
          .rightTriangle();
        }
      }
      &.last{
        &::before{
          .leftTriangle();
        }
      }

      &.active{
        background: url("~assets/img/challenge/tab-bg-active.png") top center no-repeat;
        background-size: auto 100%;

        &.first{
          &::after{
            .rightTriangle(#FA1F21);
          }
        }
        &.second{
          &::before{
            .leftTriangle(#FA1F21);
          }
          &::after{
            .rightTriangle(#FA1F21);
          }
        }
        &.last{
          &::before{
            .leftTriangle(#FA1F21);
          }
        }
      }
    }
  }

  // 申请队长、创建战队
  .captain-status-box{
    width: 1020px;
    height: 500px;
    margin: 50px auto 0;
    background: url("~assets/img/challenge/captain-bg.png") top center no-repeat;
    background-size: auto 100%;

    &.low{
      margin-top: 0;
    }

    .captain-form-box{
      width: 410px;
      margin: 0 auto;
      padding-top: 45px;

      .captain-form{
        position: relative;
        margin-bottom: 78px;
        padding-left: 10px;
        color: #fff;

        label{
          position: absolute;
          left: 100%;
          top: 0;
          z-index: 1;
          display: block;
          width: 300px;
          text-align: left;
          .label-border{
            float: left;
            margin-left: 8px;
            background: linear-gradient(135deg, transparent 5px, #747482 0);

            span{
              display: inline-block;
              height: 28px;
              line-height: 28px;
              padding: 0 14px;
              cursor: pointer;
            }
            &.active{
              background: linear-gradient(135deg, transparent 5px, #FA1E20 0);
            }
          }
        }
        .form-wrap{
          display: flex;
          align-items: flex-end;
        }
        .captain-form-label{
          position: relative;
          display: inline-block;
          width: 80px;
          height: 40px;
          padding: 19px 0 15px;
          flex-shrink: 0;
          text-align: center;
          color: #fff;
          background: url("~assets/img/challenge/captain-info-bg.png") top center no-repeat;
          background-size: cover;
          i{
            display: block;
            font-style: normal;
            text-align: center;
            line-height: 40px;
          }
        }
        .captain-form-input{
          float: left;
          width: 319px;
          height: 47px;
          background: url("~assets/img/challenge/input-bg.png") top center no-repeat;
          background-size: auto 100%;
          input{
            display: inline-block;
            width: 279px;
            height: 20px;
            padding: 17px 20px 10px;
            border: none;
            background-color: transparent;
          }
        }
        .captain-form-error{
          position: relative;
          margin-top: 12px;
          font-size: 14px;
          color: #FFDB0F;
          .form-error-text{
            position: absolute;
          }
        }
      }
    }
    .captain-status-img{
      display: block;
      margin: 0 auto;
      padding-top: 76px;
      width: 160px;
      height: 160px;
    }
    .captain-status-title{
      margin-top: 28px;
      text-align: center;
      font-size: 20px;
      color: #FFF;
    }
    .captain-status-subtitle{
      margin-top: 8px;
      text-align: center;
      font-size: 14px;
      color: #FFF;
    }
    .captain-status-btn{
      margin: 30px auto 0;
      text-align: center;

      &.apply{
        margin-top: 0;
      }
    }

    &.is-captain{
      height: 305px;
      background: url("~assets/img/challenge/captain-small-bg.png") top center no-repeat;
      background-size: auto 100%;

      .my-team-info{
        width: 880px;
        margin: 0 auto;
        padding-top: 60px;
        color: #fff;
        div{
          float: left;
        }
        .avatar-box{
          width: 66px;
          height: 66px;
          text-align: center;
          background: url('~assets/img/challenge/avatar-list.svg') bottom right no-repeat;
          img{
            display: inline-block;
            width: 50px;
            height: 50px;
            margin-top: 8px;
          }
        }
        .team-base{
          margin-left: 14px;
          font-size: 12px;
          span{
            display: block;

            &.team-info-type{
              margin-bottom: 10px;
              font-size: 20px;
            }
            .team-invite-code{
              margin-left: 15px;
              padding: 4px 8px;
              font-size: 14px;
              font-style: normal;
              background: #585868;
            }
            .icon-copy{
              position: relative;
              top: 2px;
              display: inline-block;
              width: 16px;
              height: 16px;
              margin-left: 10px;
              cursor: pointer;
            }
          }
        }
        .team-info-btn{
          float: right;
        }
      }
      .my-team-total{
        width: 840px;
        margin: 0 auto;
        padding: 20px;
        background: linear-gradient(-135deg, transparent 16px, #585868 0);
        div{
          float: left;
          width: 25%;
          text-align: center;
          color: #fff;
          span{
            display: block;
            margin-bottom: 6px;
            font-size: 14px;
            color: #BEBEBE;
            &:first-child{
              font-size: 30px;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .challenge-type-box{
    width: 1020px;
    margin: 70px auto 0;
    color: #fff;
    .challenge-type-item{
      float: left;
      width: 280px;
      height: 80px;
      margin-right: 30px;
      text-align: center;
      font-size: 24px;
      background: url("~assets/img/challenge/type-bg.png") top center no-repeat;
      background-size: auto 100%;
      span{
        position: relative;
        display: inline-block;
        margin-top: 16px;
        padding: 0 12px 10px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        &::before{
          content: ' ';
          position: absolute;
          bottom: -1px;
          left: 0;
          display: block;
          width: 2px;
          height: 4px;
          background-color: transparent;
        }
        &::after{
          content: ' ';
          position: absolute;
          bottom: -1px;
          right: 0;
          display: block;
          width: 2px;
          height: 4px;
          background-color: transparent;
        }
      }
      &.active{
        background: url("~assets/img/challenge/type-bg-active.png") top center no-repeat;
        background-size: auto 100%;

        span{
          border-color: #F41111;
          &::before, &::after{
            background-color: #F41111;
          }
        }
      }
    }
    .challenge-type-tip {
      float: left;
      width: 354px;
      height: 74px;
      padding-top: 6px;
      background: linear-gradient(-135deg, transparent 15px, #34343E 0);

      span{
        display: block;
        padding-left: 6px;
        font-size: 12px;
      }

    }
  }

}
</style>
