<template>
  <div class="main-weex">
    <my-header />
    <div class="contract-main-box lay-box clearfix">
      <div class="container clearfix">
        <div class="contract-aside-box">
          <div class="aside-h3">
            <div class="title-icon"></div>
            {{$t('contract.infomation.aside_h3')}}
          </div>
          <ul class="aside-list">
						<li><nuxt-link :to="$i18n.path('contract/fundratepro')">{{$t('contract.trade.extra_funds_rate_pro')}}</nuxt-link></li>
						<li><nuxt-link :to="$i18n.path('contract/fundrate')">{{$t('contract.infomation.fundRate')}}</nuxt-link></li>
            <!-- <li><nuxt-link :to="$i18n.path('contract/settlement')">{{$t('contract.infomation.settlement')}}</nuxt-link></li> -->
            <li><nuxt-link :to="$i18n.path('contract/positionhistory')">{{$t('contract.infomation.positionHistory')}}</nuxt-link></li>
            <li><nuxt-link :to="$i18n.path('contract/positiongear')">{{$t('contract.infomation.positionGear')}}</nuxt-link></li>
          </ul>
        </div>
        <div class="contract-box">
          <nuxt/>
        </div>
      </div>
    </div>
    <my-footer />
  </div>
</template>

<script>
import MyFooter from "~/web-base/components/Footer.vue";
import MyHeader from "~/web-base/components/Header.vue";
import { hideGlobalLoading } from '../utils/utils.js'

export default {
  components: {
    MyFooter,
    MyHeader
  },
  mounted() {
    hideGlobalLoading()
  }
};
</script>

<style lang="less">
.contract-main-box{
  min-height: 700px;
  .container{
     .pr();
     width: 100%;
     display: flex;
     justify-content: space-between;
  }
  .aside-h3{
    margin: 0;padding: 0;
    padding-left: 37px;
    font-size: 16px;
    color: @text-black;
    line-height: 16px;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .title-icon {
      width: 20px;
      height: 20px;
      background-size: cover;
      background-image: url('~assets/img/contract/aside-icon.png');
      display: inline-block;
      margin-right: 10px;
    }
  }
  .contract-aside-box{
    min-width: 240px;
    max-width: 264px;
    width: 15%;
    box-sizing: border-box;
    padding-top: 40px;
    border-right: 1px solid #F1F3F6;

    .aside-list{
      margin-top: 20px;
      > li{
        > a{
          display: flex;
          align-items: center;
          min-height: 46px;
          height: auto;
          padding-left: 64px;
          font-size: 14px;
          color: @text-black;
          line-height: 1.2;
          border-left: 4px solid transparent;
          &.nuxt-link-active{
            background: #F5F5F5;
            color: @text-black;
            font-weight: bold;
            border-color: @yellow-P3;
          }
          &:hover{
            background: #F5F5F5;
            color: @text-black;
            font-weight: bold;
            border-color: @yellow-P3;
          }
          .iconfont{margin-right: 12px;}
          .icon-wallet{font-size: 13px;}
          .el-icon-arrow-right{
            margin-right: 30px;
            line-height: 46px;
          }

          &.show-box{
            position: relative;
            .show-details{
              display: none;
              position: absolute;
              left: 230px;
              top: 0;
              width: 130px;
              padding-left: 30px;
              background-color: #fff;
              box-shadow: 0px 0px 20px rgba(0, 0, 0, .08);
              z-index: 2;
              > li > a{
                display: block;height: 46px;font-size: 14px;line-height: 46px;color: #444;transition: all .35s;
                &:hover{
                  color: #5684f4;
                }
              }
            }
            &:hover{
              .show-details{
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .contract-box{
    padding-top: 50px;
    padding-bottom: 20px;
    flex: 1;
  }
}
</style>
