
<template>
  <!-- 个人中心- 左侧菜单 -->
  <div class="main-weex2">
    <my-header />
    <div class="personal-main-container lay-box clearfix">
      <div class="personal-container">
        <div class="personal-left-box">
          <ul class="left-main-list">
            <li>
              <!-- 资产总览 -->
              <nuxt-link exact :to="$i18n.path('asset')" @click.native="setToggleIcon()">
                <i class="iconfont icon-zichanzonglan assets-icons icon-1"></i>
                <span>{{ $t("user_overview.user_view") }}</span>
              </nuxt-link>
            </li>
            <li>
              <!-- 充币 -->
              <nuxt-link
                :class="{ active: isClassActive('asset/recharge') }"
                :to="$i18n.path('asset/recharge')"
                @click.native="setToggleIcon()"
              >
                <i class="iconfont icon-chongbi1 assets-icons icon-2"></i>
                <span>{{ $t("assets.charge_money.title") }}</span>
              </nuxt-link>
            </li>
            <li>
              <!-- 提币 -->
              <nuxt-link
                :class="{
                  active:
                    isClassActive('asset/withdraw') ||
                    isClassActive('asset/address')
                }"
                :to="$i18n.path('asset/withdraw')"
                @click.native="setToggleIcon()"
              >
                <i class="iconfont icon-tibi1 assets-icons icon-3"></i>
                <span>{{ $t("assets.common.coin_withdraw") }}</span>
              </nuxt-link>
            </li>
            <li>
              <!-- 资金划转 -->
              <nuxt-link
                :class="{ active: isClassActive('asset/transfer') }"
                :to="$i18n.path('asset/transfer')"
                @click.native="setToggleIcon()"
              >
                <i class="iconfont icon-zijinhuazhuan assets-icons icon-4"></i>
                <span>{{ $t("lever.transferDialogTitle") }}</span>
              </nuxt-link>
            </li>
            <li>
              <!-- 财务记录 -->
              <div class="menu" @click="financeRecordShow = !financeRecordShow">
                <div class="menu-text">
                  <i class="iconfont icon-caiwujilu1 assets-icons icon-5" :class="toggleIcon.record ? 'child-active' : ''"></i>
                  <span>{{ $t("links.financial") }}</span>
                </div>
                <i class="iconfont icon-up arrow" :class="{ show: financeRecordShow }"></i>
              </div>
              <transition name="common-fade">
                <div v-if="financeRecordShow" class="submenu">
                  <ul>
                    <li>
                      <!-- 币币记录 -->
                      <nuxt-link exact :to="$i18n.path('record/exchange')" @click.native="setToggleIcon('record')">
                        <span>{{$t("new_assets.exchange_title")}}</span>
                      </nuxt-link>
                    </li>
                    <li v-if="!contractSwitch">
                      <!-- 合约记录 -->
                      <nuxt-link exact :to="$i18n.path('record/contract')" @click.native="setToggleIcon('record')">
                        <span>{{$t("new_assets.contract_title")}}</span>
                      </nuxt-link>
                    </li>
                    <li v-if="!contractSwitch">
                      <!-- 合约Pro记录 -->
                      <nuxt-link exact :to="$i18n.path('record/newcontract')" @click.native="setToggleIcon('record')">
                        <span>{{$t("new_assets.new_contract_title")}}</span>
                      </nuxt-link>
                    </li>
                    <!-- OTC记录 -->
                    <!-- <li v-if="isCN">
                      <nuxt-link exact :to="$i18n.path('record/otc')">
                        <span>{{$t("new_assets.otc_title")}}</span>
                      </nuxt-link>
                    </li> -->
                  </ul>
                </div>
              </transition>
            </li>
            <li>
              <!-- 交易记录 -->
              <div class="menu" @click="orderRecordShow = !orderRecordShow">
                <div class="menu-text">
                  <i class="iconfont icon-jiaoyidingdan assets-icons icon-6" :class="toggleIcon.transac ? 'child-active' : ''"></i>
                  <span>{{ $t("user_aside.record") }}</span>
                </div>
                <i class="iconfont icon-up arrow" :class="{ show: orderRecordShow }"></i>
              </div>
              <transition name="el-zoom-in-top">
                <div v-if="orderRecordShow" class="submenu">
                  <ul>
                    <li>
                      <!-- 现货交易记录 -->
                      <nuxt-link exact :to="$i18n.path('transac')" @click.native="setToggleIcon('transac')">
                        <span>{{$t("links.actuals_record")}}</span>
                      </nuxt-link>
                    </li>
                    <li v-if="!contractSwitch">
                      <!-- 合约交易记录 -->
                      <nuxt-link exact :to="$i18n.path('transac/contract')" @click.native="setToggleIcon('transac')">
                        <span>{{$t("links.contract_record")}}</span>
                      </nuxt-link>
                    </li>
                    <li>
                      <!-- 新合约交易记录 -->
                      <nuxt-link exact :to="$i18n.path('transac/newcontract')" @click.native="setToggleIcon('transac')">
                        <span>{{$t("links.new_contract_record")}}</span>
                      </nuxt-link>
                    </li>
                  </ul>
                </div>
              </transition>
            </li>
          </ul>
        </div>
        <div class="personal-main-box">
          <div class="personal-main-content">
            <nuxt />
          </div>
        </div>
      </div>
      <Telegram></Telegram>
    </div>
    <my-footer />
  </div>
</template>

<script>
import MyFooter from '~/web-base/components/Footer.vue'
import MyHeader from '~/web-base/components/Header.vue'
import Telegram from '~/web-base/components/Telegram.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  components: {
    MyFooter,
    MyHeader,
    Telegram
  },
  computed: {
    ...mapGetters(['isCN']),
    ...mapState({
      contractSwitch: state => state.contractAndFollowSwitch.contractSwitch
    }),
    currPath() {
      return this.$route.path
    }
  },
  data() {
    return {
      financeRecordShow: false,
      orderRecordShow: false,
      toggleIcon: {
        record: false,
        transac: false,
      }
    }
  },
  watch: {
    $route(to) {
      //判断router 是否是 记录
      this.init(to.path)
    }
  },
  mounted() {
    this.init(this.$route.path)
  },
  methods: {
    init(path) {
      if (path.indexOf('record') > -1) {
        this.financeRecordShow = true
        this.orderRecordShow = false
      }
      if (path.indexOf('transac') > -1 || path.indexOf('order/') > -1) {
        this.orderRecordShow = true
        this.financeRecordShow = false
      }
    },
    //判断当前路由下 菜单是否active
    isClassActive(path) {
      if (this.$route.path.indexOf(path) > -1) {
        return true
      }
      return false
    },
    setToggleIcon(name=''){
      for (let _key in this.toggleIcon) {
        this.toggleIcon[_key] = false
      }
      if (name) this.toggleIcon[name] = true
    }
  }
}
</script>

<style lang="less">
.hover_a() {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  padding-left: 32px;
  padding-right: 24px;
  font-size: 14px;
  color: @text-black;
  border-right-width: 0;
  border-left: 4px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  &:hover,
  &.active,
  &.nuxt-link-exact-active {
    background: #F5F5F5;
    color: @text-black;
    font-weight: bold;
    border-color: @yellow-P3;
    i {
      color: @yellow-P3;
    }
  }
  i {
    font-size: 16px;
    margin-right: 10px;
    color: @text-minor-color;
  }
}

.personal-main-container {
  .personal-container {
    display: flex;
    justify-content: space-between;
  }
  .personal-left-box {
    min-width: 240px;
    max-width: 264px;
    width: 15%;
    box-sizing: border-box;
    padding-top: 40px;
    border-right: 1px solid @table-header-color-border;

    .left-main-list {
      > li {
        line-height: 48px;
        > a {
          .hover_a();
          display: flex;
          align-items: center;
          i {
            color: @text-black;
          }
        }
        //有下级菜单的
        .menu {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 36px;
          padding-right: 24px;
          box-sizing: border-box;
          cursor: pointer;
          width: 100%;
          .arrow {
            font-size: 17px;
            color: @text-normal-color;
            transform: scale(0.6) rotate(180deg);
            transition: all 0.2s;
            &.show {
              transform: scale(0.6) rotate(0deg);
              transition: all 0.2s;
            }
            &:before {
              content: "\e623";
            }
          }
          .menu-text {
            display: flex;
            align-items: center;
            line-height: 1;
            i {
              font-size: 16px;
              margin-right: 10px;
              color: @text-black;
            }
            span {
              color: @text-black;
            }
            .child-active {
              color: @yellow-P3;
              font-weight: bold;
            }
          }
        }
        .submenu {
          ul {
            > li {
              height: auto;
              min-height: 48px;
              line-height: 48px;
              > a {
                .hover_a();
                padding-left: 58px;
                color: @text-normal-color;

                > span {
                  position: relative;
                  display: inline-block;
                  width: 100%;
                  font-size: 14px;
                  line-height: 1.2;
                  white-space: unset;

                  .icon-newhot {
                    position: absolute;
                    right: -10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  //右侧区域
  .personal-main-box {
    flex: 1;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 48px;
    display: flex;
    justify-content: center;
    .personal-main-content {
      flex: 1;
      min-width: 960px;
      max-width: 1560px;
    }
  }

  .assets-icons {
    font-family: "assets-icons" !important;
    font-size: 20px !important;
    &.icon-1:before { content: "\e900"; font-style:normal;}
    &.icon-2:before { content: "\e901"; font-style:normal;}
    &.icon-3:before { content: "\e902"; font-style:normal;}
    &.icon-4:before { content: "\e903"; font-style:normal;}
    &.icon-5:before { content: "\e904"; font-style:normal;}
    &.icon-6:before { content: "\e905"; font-style:normal;}
  }
}
</style>
