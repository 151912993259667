import { toShortNumber } from '@components/trade/src/formatter';

export default {
  /**
   * 金额格式化
   * @param {number} amount 金额
   * @param {boolean} [isRound] 是否四舍五入 默认否
   * @param {string} [splitStr] 是否使用分隔符 默认否
   * @param {number} [digits] 小数位数
   * @returns {string} 格式化后的数字，默认两位小数 formatMoney("12345.675910", 3)
   */
  formatMoney(amount, isRound = false, splitStr = '', digits = null) {
    if (amount === null || amount === '' || isNaN(amount) || amount === undefined) {
      return '0'
    } else {
      amount = parseFloat((amount + '').replace(/[^\d\.-]/g, ''));
      if (digits === null) {
        if (amount.toString().indexOf('.') > -1) {
          digits = amount.toString().split('.')[1].length;
        } else {
          digits = 0;
        }
      }
      if (isRound) {
        amount = amount.toFixed(digits) + '';
      } else {
        // amount = amount.toFixed(digits + 1)
        // amount = amount.substring(0, amount.toString().length - 1)
        let lNum = '0',
          rNum = '00000000';
        let arr: string[] = ((amount || '0') + '').split('.');
        if (arr && arr[0]) {
          lNum = arr[0];
          if (arr[1]) rNum = (arr[1] + rNum).substr(0, digits);
        }
        amount = lNum + '.' + rNum;
      }
      let l = amount
        .split('.')[0]
        .split('')
        .reverse();
      let r = amount.split('.')[1];
      let t = '';
      if (splitStr) {
        for (let i = 0; i < l.length; i++) {
          t += l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? splitStr : '');
        }
      } else {
        for (let i = 0; i < l.length; i++) {
          t += l[i];
        }
      }
      r = r > 0 ? '.' + r : '';
      return (
        t
          .split('')
          .reverse()
          .join('') + r
      );
    }
  },
  /**
   * 格式化金额 - 纯截取模式
   * @param val 金额数值
   * @param num 保留位数
   * @returns {string}
   */
  formatMoney2(val, num = 8) {
    val = val.toString();
    let rNum = '';
    for (let i = 0; i < num; i++) rNum += '0';
    let lNum = '0';
    let arr: string[] = (val || '0').split('.');
    if (arr && arr[0]) {
      lNum = arr[0];
      if (arr[1]) rNum = (arr[1] + rNum).substr(0, num);
    }
    return num > 0 ? `${lNum}.${rNum}` : `${lNum}`;
  },

  /**
   * 格式化终端显示内容
   * @param {Number} type 登录终端类型
   */
  formatTerminalType(type) {
    if (type) {
      return type === 1 ? 'Web' : type === 2 ? 'Android' : 'iOS';
    } else {
      return type;
    }
  },

  /**
   * 格式化时间
   * @param {string} str 时间戳
   * @param {string} format 格式
   */
  moment(oldVal, format) {
    if (!oldVal) return oldVal;
    if (isNaN(Number(oldVal))) return oldVal;
    if (oldVal.toString().indexOf('-') >= 1) {
      oldVal = oldVal.replace(/-/g, '/');
    }
    let myDate = new Date(Number(oldVal));
    var o = {
      'M+': myDate.getMonth() + 1, // month,
      'd+': myDate.getDate(), // day
      'h+': myDate.getHours(), // hour
      'H+': myDate.getHours(), // hour
      'm+': myDate.getMinutes(), // minute
      's+': myDate.getSeconds(), // second
      'q+': Math.floor((myDate.getMonth() + 3) / 3), // quarter
      S: myDate.getMilliseconds() // millisecond
    };
    if (/(y+)/.test(format))
      format = format.replace(RegExp.$1, (myDate.getFullYear() + '').substr(4 - RegExp.$1.length));
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
      }
    }
    return format;
  },

  /**
   * 格式化交易对名称
   * @param {string} oldVal 原始值，可能包含_或/
   */
  formatExchangeName(oldVal) {
    if (!oldVal) {
      return '';
    }
    if (oldVal.indexOf('_') > 0) {
      return (
        oldVal
          .split('_')[0]
          .trim()
          .toUpperCase() +
        '/' +
        oldVal
          .split('_')[1]
          .trim()
          .toUpperCase()
      );
    } else if (oldVal.indexOf('/') > 0) {
      return (
        oldVal
          .split('/')[0]
          .trim()
          .toUpperCase() +
        '/' +
        oldVal
          .split('/')[1]
          .trim()
          .toUpperCase()
      );
    } else {
      return oldVal;
    }
  },

  /**
   *
   * @param {*} str 原始值类似 cmt_btcusdt
   * @returns
   */
  formatProductCode(str) {
    if (str.indexOf('_') > 0) {
      let tmp = str.split('_')[1];
      return (
        tmp
          .split('usdt')[0]
          .trim()
          .toUpperCase() +
        '/' +
        'USDT'
      );
    } else {
      return (
        str
          .split('usd')[0]
          .trim()
          .toUpperCase() + '/USD'
      );
    }
  },

  /**
   *
   * TODO: 待续
   * @param String baseSymbol 币种
   * @param Number pricedSymbol 单位
   * @param Boolean split 分隔符
   * @param String suffix 后缀，有后缀就显示，中文下可能有”永续、模拟“等字
   */
  formatProductName(baseSymbol, pricedSymbol, split = '/', suffix = '') {
    if (!baseSymbol || !pricedSymbol) {
      return '';
    }
    return baseSymbol.toUpperCase() + split + pricedSymbol.toUpperCase() + suffix;
  },

  /**
   * 按千分位格式化数字
   * @param num
   * @return {string}
   */
  toThousands(num) {
    if (!num) {
      return num;
    }
    num = num.toString().trim();

    let [integer, decimal] = num.split('.'),
      result = '',
      left = integer;

    while (left.length > 3) {
      result = ',' + left.slice(-3) + result;
      left = left.slice(0, left.length - 3);
    }
    return left + result + (decimal ? `.${decimal}` : '');
  },
  /****
   * 隐藏的数据展示 *****
   */
  showStar(val, isHide) {
    if (isHide) {
      return '****';
    }
    return val;
  },
  /**
   * 汇率换算
   * @param {*} val
   * @param {*} rate
   */
  convertValuation(val, rate) {
    if (val && rate) {
      return val * rate;
    }
    return 0;
  },
  /**
   * 添加 + 格式化
   * @param {*} val
   */
  formatPlus(val) {
    if (Number(val) > 0) {
      return '+' + val;
    }
    return val;
  },
  /**
   * 默认头像
   * @param {*} val
   */
  defaultAvatorImg(url) {
    if (url) {
      return url;
    }
    return require('~/assets/img/copy_trading/avatar-default.jpg');
  },
  upperCase(val) {
    if (val) {
      return val.toString().toUpperCase();
    }
    return '';
  },
  toShortNumber(value) {
    return toShortNumber(value, 2);
  }
};
