<template>
  <div class="main-weex">
    <my-header />
    <div class="account-main-box lay-box clearfix">
      <div class="container clearfix">
        <div class="aside-box clearfix"  >
          <div class="aside-h3">{{$t('user_aside.h3')}}</div>
          <ul class="aside-list">
            <li><nuxt-link exact :to="$i18n.path('account')"><i class="iconfont icon-zhanghuxinxi"></i>{{$t('user_aside.info')}}</nuxt-link></li>
            <li><nuxt-link :to="$i18n.path('asset')"><i class="iconfont icon-wallet"></i>{{$t('user_aside.assets')}}</nuxt-link></li>
            <li><nuxt-link :to="$i18n.path('financial')"><i class="iconfont icon-caiwujilu"></i>{{$t('links.financial')}}</nuxt-link></li>
            <li>
              <div :class="{'nuxt-link-active': currPath.indexOf('/transac') == 0}"  @mouseover="toggleShow()" @mouseout="toggleShow()" class="hover-box show-box">
                <i class="iconfont icon-jiaoyijilu1"></i>{{$t('user_aside.record')}}<i class="fr el-icon-arrow-right"></i>
                <transition name="slide-fade">
                  <div class="show-details" v-show="showDetails">
                    <nuxt-link :to="$i18n.path('transac')">{{$t('links.actuals_record')}}</nuxt-link>
                    <nuxt-link :to="$i18n.path('transac/contract')">{{$t('links.contract_record')}}</nuxt-link>
                  </div>
                </transition>
              </div>
            </li>
            <li><nuxt-link :to="$i18n.path('account/setting')"><i class="iconfont icon-anquanshezhi"></i>{{$t('user_aside.setting')}}</nuxt-link></li>
            <li><nuxt-link :to="$i18n.path('account/verified')"><i class="iconfont icon-shimingrenzheng"></i>{{$t('user_aside.verified')}}</nuxt-link></li>
            <li><nuxt-link exact :to="$i18n.path('account/newapi')"><i class="iconfont icon-api"></i>{{$t('user_aside.api')}}</nuxt-link></li>
          </ul>
        </div>
        <div class="user-main-box">
          <nuxt/>
        </div>
      </div>
       <Telegram></Telegram>
    </div>
    <my-footer />
  </div>
</template>

<script>
import MyFooter from "~/web-base/components/Footer.vue";
import MyHeader from "~/web-base/components/Header.vue";
import Telegram from "~/web-base/components/Telegram.vue";
export default {
  components: {
    MyFooter,
    MyHeader,
    Telegram
  },
  computed: {
    currPath() {
      return this.$route.path
    }
  },
  data(){
     return {
       height:'',
       agentFlag: "",
       showDetails: false
     }
  },
  watch: {
  },
  mounted(){
  },
  methods: {
    toggleShow() {
      this.showDetails = !this.showDetails
    }
  }
};
</script>

<style lang="less">
.account-main-box{
  min-height: 700px;
  .container{
     .pr();
  }
  .aside-h3{
    margin: 0;padding: 0;
    padding-left: 37px;
    font-size: 16px;
    color: @text-black;
    line-height: 16px;
    font-weight: 500;
  }
  .aside-box{
   .pa();
   left: 0;width: 230px;min-height: 700px;height: 100%;margin-right: 50px;padding-top: 50px;padding-left: 10px;background-color: #f3f7fe;z-index: 3;
    .aside-list{
      margin-top: 35px;background-color: #f3f7fe;
      > li{
        > a,.hover-box{
          display: block;height: 46px;padding-left: 37px;font-size: 14px;line-height: 46px;color: #444;transition: all .35s;
          &.nuxt-link-active{
            color: #5684f4;
            background-color: #fff;
          }
          &:hover{
            color: #5684f4;
          }
          .iconfont{margin-right: 12px;}
          .icon-wallet{font-size: 13px;}
          .icon-zhifufangshi{font-size: 12px;}
          .el-icon-arrow-right{
            margin-right: 30px;
            line-height: 46px;
          }

          &.show-box{
            position: relative;
            .show-details{
              position: absolute;
              left: 230px;
              top: 0;
              min-width: 130px;
              padding-left: 30px;
              box-shadow: 0px 0px 20px rgba(0, 0, 0, .08);
              z-index: -1;
              > a{
                display: block;
                background-color: #fff;
                height: 46px;
                font-size: 14px;
                line-height: 46px;
                color: #444;
                transition: all .35s;
                &:hover{
                  color: #5684f4;
                }
              }
            }
          }
        }
      }
    }
  }
  .user-main-box{
    float: right;width: 910px;padding-top: 50px; padding-bottom: 20px;
  }
}
.slide-fade-enter-active {
  transition: all .2s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(0, 0, 0, 0);
}
.slide-fade-enter, .slide-fade-leave-to{
  transform: translateX(-160px);
}
</style>
