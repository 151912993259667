import enElementLocale from 'element-ui/lib/locale/lang/en';
import zhElementLocale from 'element-ui/lib/locale/lang/zh-CN';
import twElementLocale from 'element-ui/lib/locale/lang/zh-TW';
import koElementLocale from 'element-ui/lib/locale/lang/ko';
import viElementLocale from 'element-ui/lib/locale/lang/vi';

export default function ({isHMR, app, store, route, params, error, redirect}) {
  const defaultLocale = app.i18n.fallbackLocale
  // If middleware is called from hot module replacement, ignore it
  if (isHMR) return
  // Get locale from params
  //let locale = params.lang ||defaultLocale;

  let locale = params.lang
  //404 页面 params.lang 获取的是空字符串
  if(!locale){
    locale=  store.state.locales.find((local)=>{
      return  route.fullPath.indexOf(`/${local}/`)>-1
   }) || defaultLocale
  }
  if (!store.state.locales.includes(locale)) {
    return error({ message: 'This page could not be found.', statusCode: 404 })
  }
  // Set locale
  loadLanguageAsync(app.i18n, locale).then(locale => {
    let i18nLocale = app.i18n.locale;
    store.commit('SET_LANG', locale)
    app.i18n.locale = locale
    app.i18n.setLocaleMessage(i18nLocale, {})
  })


  //If route is /<defaultLocale>/... -> redirect to /...
  // if (locale === defaultLocale && route.fullPath.indexOf('/' + defaultLocale) === 0) {
  //   // 当语言参数和缺省语言相同，且url中语言和缺省语言相通，将缺省语言省略
  //   const toReplace = '^/' + defaultLocale + (route.fullPath.indexOf('/' + defaultLocale + '/') === 0 ? '/' : '')
  //   const re = new RegExp(toReplace)
  //   return redirect(
  //     route.fullPath.replace(re, '/')
  //   )
  // }
}



export function loadLanguageAsync(i18n, locale) {
  return new Promise((resolve) =>{
    // 如果语言相同
    if (i18n.locale !== locale) {
      let messages = () => import(`~/locales/en.json`);
      switch (locale) {
        case 'zh-CN':
          messages = () => import(`~/locales/zh-cn.json`);
          break;
        case 'zh-TW':
          messages = () => import(`~/locales/zh-tw.json`);
          break;
        case 'ko':
          messages = () => import(`~/locales/ko-kr.json`);
          break;
        case 'vi':
          messages = () => import(`~/locales/vi.json`);
          break;
      }
       messages().then(message => {
         switch (locale) {
           case 'zh-CN':
             i18n.setLocaleMessage(locale, {...message.default, ...zhElementLocale})
             break;
           case 'zh-TW':
             i18n.setLocaleMessage(locale, {...message.default, ...twElementLocale})
             break;
           case 'ko':
             i18n.setLocaleMessage(locale, {...message.default, ...koElementLocale})
             break;
           case 'vi':
             i18n.setLocaleMessage(locale, {...message.default, ...viElementLocale})
             break;
           default:
             i18n.setLocaleMessage(locale, {...message.default, ...enElementLocale})
             break;
         }
          resolve(locale)
       })
    }
  })
}

