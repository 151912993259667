<template>
  <div id="mainConWrap" style="min-width:1350px;">
    <nuxt/>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  head() {
    return {
      title: this.$i18n.t('common.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$i18n.t('common.description')
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.$i18n.t('common.keywords')
        }
      ]
    };
  }
};
</script>
